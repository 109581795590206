import { Box, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import QuotaEditIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import { Menu } from "components/common/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { TAB_TITLES as LB_TAB_TITLES } from "components/LoadBalancer";
import { TABS as LB_TABS } from "components/LoadBalancer/types";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import {
  FormDialog,
  selectOptionSchema,
  selectOptionSchemaNotRequired
} from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import * as loadBalancersActions from "modules/loadBalancers/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import { tableVirtualMachinesSelector } from "modules/instances/selectors";
import * as networksActions from "modules/networks/actions";
import {
  interfacesSelector,
  subnetsSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  MAX_LB_TIMEOUT,
  MAX_PORT,
  MIN_PORT,
  ROUTES
} from "../../constants";
import { DELETE_DIALOG_TYPE, DIALOG_TYPES, TABS } from "./types";
import {
  L7_POLICY_ACTION,
  L7_POLICY_ACTION_LABELS,
  LB_MONITOR_HTTP_METHODS,
  LB_MONITOR_HTTP_METHODS_LABELS,
  LB_MONITOR_TYPES,
  LB_MONITOR_TYPES_LABELS,
  LB_POOL_ALGORITHM,
  LB_POOL_ALGORITHM_LABELS,
  LB_POOL_SESSION_PERSISTENCE,
  LB_POOL_SESSION_PERSISTENCE_LABELS,
  LB_PROTOCOLS,
  TableL7Policy,
  TablePoolMember
} from "modules/loadBalancers/types";
import {
  areL7PoliciesLoadingSelector,
  arePoolMembersLoadingSelector,
  arePoolsLoadingSelector,
  isL7PolicyCreatingSelector,
  isL7PolicyDeletingSelector,
  isL7PolicyUpdatingSelector,
  isListenerDeletingSelector,
  isListenerLoadingSelector,
  isListenerUpdatingSelector,
  isLoadBalancerDeletingSelector,
  isLoadBalancerLoadingSelector,
  isLoadBalancerUpdatingSelector,
  isMonitorCreatingSelector,
  isMonitorDeletingSelector,
  isMonitorLoadingSelector,
  isMonitorUpdatingSelector,
  isPoolCreatingSelector,
  isPoolDeletingSelector,
  isPoolLoadingSelector,
  isPoolMemberAddingSelector,
  isPoolMemberRemovingSelector,
  isPoolMemberUpdatingSelector,
  isPoolUpdatingSelector,
  listenerDetailsSelector,
  loadBalancerDetailsSelector,
  monitorDetailsSelector,
  poolDetailsSelector,
  poolsSelector,
  tableL7PoliciesSelector,
  tablePoolMembersSelector,
  tablePoolsSelector
} from "modules/loadBalancers/selectors";
import * as s from "./styles";
import { getSelectOption } from "utils/getSelectOption";
import { Loader } from "components/common/Loader";
import { getParentPath } from "utils/getParentPath";
import {
  filterAvailablePoolProtocols,
  filterPoolsByListenerProtocol
} from "utils/filterProtocols";
import { isSelectOption } from "typeGuards/isSelectOption";

const POLL_ID_PREFIX = "LB_LISTENER";

const POLL_IDS = {
  loadBalancer: "LB",
  listener: "LISTENER",
  pool: "POOL",
  pools: "POOLS",
  members: "POOL_MEMBERS",
  monitor: "MONITOR",
  instances: "INSTANCES",
  interfaces: "INTERFACES",
  subnets: "SUBNETS"
};

const tableL7PoliciesColumns: TableColumn<TableL7Policy>[] = [
  { key: "name", label: "Name" },
  { key: "position", label: "Position" },
  { key: "action", label: "Action" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
];

const tablePoolMembersColumns: TableColumn<TablePoolMember>[] = [
  { key: "name", label: "Name" },
  { key: "address", label: "IP" },
  { key: "protocol_port", label: "Protocol Port" },
  { key: "weight", label: "Weight" },
  { key: "monitor_address_str", label: "Monitor Address" },
  { key: "monitor_port_str", label: "Monitor Port" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "backup_string",
    label: "Backup Member"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
  // { key: "created_at", label: "Created" }
];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.L7POLICIES]: "L7Policies",
  [TABS.POOLS]: "Pools"
};

export const LBListener: FC = () => {
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.L7POLICIES) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const [isCreatePoolInnerDialogOpened, setIsCreatePoolInnerDialogOpened] =
    useState(false);

  const handleCloseCreatePoolInnerDialog = useCallback(() => {
    setIsCreatePoolInnerDialogOpened(false);
  }, []);

  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    lbId: string;
    listenerId: string;
  }>();

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const subnets = useSelector(subnetsSelector);
  const virtualMachines = useSelector(tableVirtualMachinesSelector);
  const interfaces = useSelector(interfacesSelector);

  const loadBalancerDetails = useSelector(loadBalancerDetailsSelector);
  const isLoadBalancerUpdating = useSelector(isLoadBalancerUpdatingSelector);
  const isLoadBalancersDeleting = useSelector(isLoadBalancerDeletingSelector);

  const isLoadBalancersOperationInProgress =
    isLoadBalancerUpdating || isLoadBalancersDeleting;
  const previousIsLoadBalancersOperationInProgress = usePrevious(
    isLoadBalancersOperationInProgress
  );

  const listenerDetails = useSelector(listenerDetailsSelector);
  const isListenerLoading = useSelector(isListenerLoadingSelector);
  const headers = listenerDetails?.insert_headers ?? {};
  const checkHeader = (key: string): boolean => headers[key] === "True";
  const isListenerUpdating = useSelector(isListenerUpdatingSelector);
  const isListenerDeleting = useSelector(isListenerDeletingSelector);

  const isListenersOperationInProgress =
    isListenerUpdating || isListenerDeleting;
  const previousIsListenersOperationInProgress = usePrevious(
    isListenersOperationInProgress
  );

  const pools = useSelector(poolsSelector);
  const tablePools = useSelector(tablePoolsSelector);
  const poolDetails = useSelector(poolDetailsSelector);
  const isPoolLoading = useSelector(isPoolLoadingSelector);
  const isPoolCreating = useSelector(isPoolCreatingSelector);
  const isPoolUpdating = useSelector(isPoolUpdatingSelector);
  const isPoolDeleting = useSelector(isPoolDeletingSelector);
  const isPoolsOperationInProgress =
    isPoolCreating || isPoolUpdating || isPoolDeleting;
  const previousIsPoolsOperationInProgress = usePrevious(
    isPoolsOperationInProgress
  );

  const monitorDetails = useSelector(monitorDetailsSelector);
  const isMonitorLoading = useSelector(isMonitorLoadingSelector);
  const isMonitorCreating = useSelector(isMonitorCreatingSelector);
  const isMonitorUpdating = useSelector(isMonitorUpdatingSelector);
  const isMonitorDeleting = useSelector(isMonitorDeletingSelector);
  const isMonitorsOperationInProgress =
    isMonitorCreating || isMonitorUpdating || isMonitorDeleting;
  const previousIsMonitorsOperationInProgress = usePrevious(
    isMonitorsOperationInProgress
  );

  const tablePoolMembers = useSelector(tablePoolMembersSelector);
  const arePoolMembersLoading = useSelector(arePoolMembersLoadingSelector);
  const isPoolMemberAdding = useSelector(isPoolMemberAddingSelector);
  const isPoolMemberUpdating = useSelector(isPoolMemberUpdatingSelector);
  const isPoolMemberRemoving = useSelector(isPoolMemberRemovingSelector);
  const isPoolMembersOperationInProgress =
    isPoolMemberAdding || isPoolMemberUpdating || isPoolMemberRemoving;
  const previousIsPoolMembersOperationInProgress = usePrevious(
    isPoolMembersOperationInProgress
  );

  const tableL7Policies = useSelector(tableL7PoliciesSelector);
  const isL7PolicyCreating = useSelector(isL7PolicyCreatingSelector);
  const isL7PolicyUpdating = useSelector(isL7PolicyUpdatingSelector);
  const isL7PolicyDeleting = useSelector(isL7PolicyDeletingSelector);
  const isL7PoliciesOperationInProgress =
    isL7PolicyCreating || isL7PolicyUpdating || isL7PolicyDeleting;
  const previousIsL7PoliciesOperationInProgress = usePrevious(
    isL7PoliciesOperationInProgress
  );

  const title = `Listener: ${listenerDetails?.name}`;

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_L7POLICY, isOpened: false });

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState<boolean>(false);

  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const generateL7PoliciesTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.L7POLICY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: matchParams.listenerId,
        l7PolicyId: id
      }),
    [
      matchParams.lbId,
      matchParams.listenerId,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const generatePoolsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.POOL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        poolId: id
      }),
    [
      matchParams.lbId,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const generateLoadBalancersTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: id
      }),
    [matchParams.organizationId, matchParams.projectId, matchParams.regionId]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "LoadBalancers",
      url: generatePath(ROUTES.LOAD_BALANCERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: loadBalancerDetails?.name || "",
      url: generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId
      })
    },
    {
      text: "Listeners",
      url: `${generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId
      })}?${generateSearchString({
        tab: LB_TAB_TITLES[LB_TABS.LISTENERS]
      })}`
    },
    {
      text: listenerDetails?.name || "",
      url: generatePath(ROUTES.LISTENER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: matchParams.listenerId
      })
    }
  ];

  const handleEditListenerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_LISTENER,
      isOpened: true
    });
  }, []);

  const handleEditLoadBalancerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_LB,
      isOpened: true
    });
  }, []);

  const handleDeleteListenerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_LISTENER,
      isOpened: true
    });
  }, []);

  const handleCreateL7PolicyButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_L7POLICY,
      isOpened: true
    });
  }, []);

  const handleAssignPoolButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ASSIGN_POOL,
      isOpened: true
    });
  }, []);

  const handleCreatePoolInner = useCallback(() => {
    setIsCreatePoolInnerDialogOpened(true);
  }, []);

  const handleEditL7PolicyMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_L7POLICY,
      isOpened: true
    });
  }, []);

  const handleDeleteL7PolicyMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_L7POLICY,
      isOpened: true
    });
  }, []);

  const handleEditPoolMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_POOL,
      isOpened: true
    });
  }, []);

  const handleDeletePoolButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_POOL,
      isOpened: true
    });
  }, []);

  const handleCreateHealthMonitorButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_HEALTH_MONITOR,
      isOpened: true
    });
  }, []);

  const handleEditHealthMonitorMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_HEALTH_MONITOR,
      isOpened: true
    });
  }, []);

  const handleDeleteHealthMonitorMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_HEALTH_MONITOR,
      isOpened: true
    });
  }, []);

  const handleAddPoolMemberButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_POOL_MEMBER,
      isOpened: true
    });
  }, []);

  const handleEditPoolMemberMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_POOL_MEMBER,
      isOpened: true
    });
  }, []);

  const handleRemovePoolMemberMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REMOVE_POOL_MEMBER,
      isOpened: true
    });
  }, []);

  const handleDeleteMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      deleteButtonRef.current = e.currentTarget;
      setIsDeleteMenuOpen(true);
    },
    []
  );

  const handleDeleteMenuClose = useCallback(() => {
    setIsDeleteMenuOpen(false);
  }, []);

  const handleDeletePoolMenuClick = useCallback(
    (type: DELETE_DIALOG_TYPE) => () => {
      if (type === DELETE_DIALOG_TYPE.DELETE_POOL) {
        setDialog({
          type: DIALOG_TYPES.DELETE_POOL,
          isOpened: true
        });
      }

      if (type === DELETE_DIALOG_TYPE.UNASSIGN_POOL) {
        setDialog({
          type: DIALOG_TYPES.UNASSIGN_POOL,
          isOpened: true
        });
      }
      handleDeleteMenuClose();
    },
    [handleDeleteMenuClose]
  );

  const tablePoolMembersActions: TableRowActionsMenuItem<TablePoolMember>[] = [
    {
      label: "Edit",
      handler: handleEditPoolMemberMenuItemClick
    },
    {
      label: "Delete",
      handler: handleRemovePoolMemberMenuItemClick
    }
  ];

  const tableL7PoliciesActions: TableRowActionsMenuItem<TableL7Policy>[] = [
    {
      label: "Edit",
      handler: handleEditL7PolicyMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Delete",
      handler: handleDeleteL7PolicyMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      networksActions.getNetworks.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      networksActions.getFloatingIPs.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.subnets}`,
        action: networksActions.getSubnets.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.interfaces}`,
        action: networksActions.getInterfaces.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.instances}`,
        action: instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.loadBalancer}`,
        action: loadBalancersActions.getLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.listener}`,
        action: loadBalancersActions.getListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          listenerId: matchParams.listenerId!
        })
      })
    );
    // dispatch(
    //   pollingActions.startPolling({
    //     id: `${POLL_ID_PREFIX}/${POLL_IDS.policies}`,
    //     action: loadBalancersActions.listL7Policies.started({
    //       regionId: matchParams.regionId!,
    //       projectId: matchParams.projectId!,
    //       listenerId: matchParams.listenerId!
    //     })
    //   })
    // );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.pools}`,
        action: loadBalancersActions.listPools.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
    dispatch(loadBalancersActions.clear());
  });

  // Listener → Pool → Members → Monitor
  useEffect(() => {
    const defaultPoolId = listenerDetails?.default_pool_id;

    if (!defaultPoolId) {
      dispatch(loadBalancersActions.clearPoolDetails());
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.pool}`
        })
      );
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.members}`
        })
      );
      return;
    }

    if (defaultPoolId && poolDetails?.id !== defaultPoolId) {
      dispatch(loadBalancersActions.clearPoolDetails());
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.pool}`
        })
      );
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.members}`
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.pool}`,
          action: loadBalancersActions.getPool.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            poolId: defaultPoolId
          })
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.members}`,
          action: loadBalancersActions.listPoolMembers.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: defaultPoolId
          })
        })
      );
    }
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    listenerDetails?.default_pool_id,
    poolDetails?.id
  ]);

  // previousIsListenersOperationInProgress → get Listener + clear pool, monitor, members
  useEffect(() => {
    if (
      previousIsListenersOperationInProgress &&
      !isListenersOperationInProgress
    ) {
      if (!listenerDetails) {
        history(getParentPath(location.pathname, 2));
      } else {
        dispatch(
          loadBalancersActions.getListener.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            listenerId: matchParams.listenerId!
          })
        );
      }
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.lbId,
    matchParams.listenerId,
    history,
    previousIsListenersOperationInProgress,
    isListenersOperationInProgress,
    listenerDetails
  ]);

  // poolDetails && poolDetails.healthmonitor_id → getHealthMonitor
  useEffect(() => {
    if (poolDetails?.healthmonitor_id) {
      dispatch(loadBalancersActions.clearMonitorDetails());
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`,
          action: loadBalancersActions.getHealthMonitor.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: poolDetails.id,
            monitorId: poolDetails.healthmonitor_id
          })
        })
      );
    } else {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`
        })
      );
      dispatch(loadBalancersActions.clearMonitorDetails());
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    poolDetails?.id,
    poolDetails?.healthmonitor_id
  ]);

  // previousIsPoolMembersOperationInProgress → listPoolMembers
  useEffect(() => {
    if (
      previousIsPoolMembersOperationInProgress &&
      !isPoolMembersOperationInProgress &&
      listenerDetails?.default_pool_id
    ) {
      dispatch(
        loadBalancersActions.listPoolMembers.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          poolId: listenerDetails.default_pool_id
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsPoolMembersOperationInProgress,
    isPoolMembersOperationInProgress,
    listenerDetails?.default_pool_id
  ]);

  // previousIsPoolsOperationInProgress → getPool + members + monitor
  useEffect(() => {
    if (previousIsPoolsOperationInProgress && !isPoolsOperationInProgress) {
      if (!poolDetails) {
        dispatch(loadBalancersActions.clearPoolDetails());
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.pool}`
          })
        );
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.members}`
          })
        );
        dispatch(
          loadBalancersActions.getListener.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            listenerId: matchParams.listenerId!
          })
        );
        dispatch(
          loadBalancersActions.listPools.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!
          })
        );
      }
      if (poolDetails) {
        dispatch(
          loadBalancersActions.getPool.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            poolId: poolDetails.id
          })
        );
        dispatch(
          loadBalancersActions.listPoolMembers.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: poolDetails.id
          })
        );
        dispatch(
          loadBalancersActions.listPools.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!
          })
        );
        if (poolDetails.healthmonitor_id) {
          dispatch(loadBalancersActions.clearMonitorDetails());
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`
            })
          );
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`,
              action: loadBalancersActions.getHealthMonitor.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!,
                poolId: poolDetails.id,
                monitorId: poolDetails.healthmonitor_id
              })
            })
          );
          // dispatch(
          //   loadBalancersActions.getHealthMonitor.started({
          //     regionId: matchParams.regionId!,
          //     projectId: matchParams.projectId!,
          //     poolId: poolDetails.id,
          //     monitorId: poolDetails.healthmonitor_id
          //   })
          // );
        }
      }
    }
  }, [
    previousIsPoolsOperationInProgress,
    isPoolsOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.lbId,
    poolDetails,
    matchParams.listenerId
  ]);

  // previousIsMonitorsOperationInProgress → getHealthMonitor:
  useEffect(() => {
    if (
      previousIsMonitorsOperationInProgress &&
      !isMonitorsOperationInProgress &&
      poolDetails
    ) {
      dispatch(
        loadBalancersActions.getPool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          poolId: poolDetails.id
        })
      );
      if (!monitorDetails) {
        dispatch(loadBalancersActions.clearMonitorDetails());
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.monitor}`
          })
        );
      } else {
        dispatch(
          loadBalancersActions.getHealthMonitor.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: poolDetails.id,
            monitorId: monitorDetails.id
          })
        );
      }
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    poolDetails,
    previousIsMonitorsOperationInProgress,
    monitorDetails,
    isMonitorsOperationInProgress,
    matchParams.lbId
  ]);

  // previousIsLoadBalancersOperationInProgress → getLoadBalancer
  useEffect(() => {
    if (
      previousIsLoadBalancersOperationInProgress &&
      !isLoadBalancersOperationInProgress
    ) {
      dispatch(
        loadBalancersActions.getLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsLoadBalancersOperationInProgress,
    isLoadBalancersOperationInProgress,
    matchParams.lbId
  ]);

  // useEffect(() => {
  //   if (
  //     previousIsL7PoliciesOperationInProgress &&
  //     !isListenersOperationInProgress
  //   ) {
  //     dispatch(
  //       pollingActions.stopPolling({
  //         id: `${POLL_ID_PREFIX}/${POLL_IDS.policies}`
  //       })
  //     );
  //     dispatch(loadBalancersActions.clearL7PoliciesList());
  //     dispatch(
  //       pollingActions.startPolling({
  //         id: `${POLL_ID_PREFIX}/${POLL_IDS.policies}`,
  //         action: loadBalancersActions.listL7Policies.started({
  //           regionId: matchParams.regionId!,
  //           projectId: matchParams.projectId!,
  //           listenerId: matchParams.listenerId!
  //         })
  //       })
  //     );
  //     // dispatch(
  //     //   loadBalancersActions.listL7Policies.started({
  //     //     regionId: matchParams.regionId!,
  //     //     projectId: matchParams.projectId!,
  //     //     listenerId: matchParams.listenerId!
  //     //   })
  //     // );
  //   }
  // }, [
  //   dispatch,
  //   matchParams.projectId,
  //   matchParams.regionId,
  //   previousIsL7PoliciesOperationInProgress,
  //   matchParams.listenerId,
  //   isListenersOperationInProgress
  // ]);

  const handleConfirmEditLoadBalancer = useCallback(
    (data: {
      name: string;
      description: string;
      lb_admin_state_up: boolean;
    }) => {
      dispatch(
        loadBalancersActions.updateLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            description: data.description.trim(),
            admin_state_up: data.lb_admin_state_up
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId,
      handleCloseDialog
    ]
  );

  const handleConfirmEditListener = useCallback(
    (data: {
      name: string;
      description: string;
      connection_limit: number;
      client_data_timeout?: number;
      member_connection_timeout?: number;
      member_data_timeout?: number;
      x_forwarded_for?: boolean;
      x_forwarded_proto?: boolean;
      x_forwarded_port?: boolean;
      default_pool?: SelectOption;
      listener_admin_state_up: boolean;
    }) => {
      const rawHeaders = {
        "X-Forwarded-For": data.x_forwarded_for,
        "X-Forwarded-Proto": data.x_forwarded_proto,
        "X-Forwarded-Port": data.x_forwarded_port
      };

      const insertHeaders = Object.entries(rawHeaders).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value ? "True" : "False";
          }
          return acc;
        },
        {} as Record<string, string>
      );

      dispatch(
        loadBalancersActions.updateListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          listenerId: matchParams.listenerId!,
          data: {
            name: data.name,
            description: data.description.trim(),
            admin_state_up: data.listener_admin_state_up,
            connection_limit: data.connection_limit,
            default_pool_id: data.default_pool?.value,
            timeout_client_data: data.client_data_timeout,
            timeout_member_connect: data.member_connection_timeout,
            timeout_member_data: data.member_data_timeout,
            insert_headers:
              Object.keys(insertHeaders).length > 0 ? insertHeaders : undefined
          }
        })
      );

      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId,
      matchParams.listenerId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteListener = useCallback(() => {
    dispatch(
      loadBalancersActions.deleteListener.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!,
        listenerId: matchParams.listenerId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    matchParams.listenerId,
    handleCloseDialog
  ]);

  const handleConfirmAssignPool = useCallback(
    (data: { default_pool?: SelectOption }) => {
      dispatch(
        loadBalancersActions.updateListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          listenerId: matchParams.listenerId!,
          data: {
            default_pool_id: data.default_pool?.value
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.lbId,
      matchParams.listenerId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmUnassignPool = useCallback(() => {
    dispatch(
      loadBalancersActions.updateListener.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!,
        listenerId: matchParams.listenerId!,
        data: {
          default_pool_id: ""
        }
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.lbId,
    matchParams.listenerId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmCreatePoolInner = useCallback(
    (data: {
      name: string;
      pool_protocol?: SelectOption;
      algorithm: string;
      session_persistence?: SelectOption;
      cookie_name?: string;
    }) => {
      dispatch(
        loadBalancersActions.createPool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            protocol:
              data.pool_protocol?.value || listenerDetails?.protocol || null,
            lb_method: data.algorithm,
            persistence: data.session_persistence
              ? {
                  type:
                    data.session_persistence?.value === "None"
                      ? ""
                      : data.session_persistence?.value,
                  cookie_name: data.cookie_name
                }
              : null,
            admin_state_up: true
          }
        })
      );
      handleCloseCreatePoolInnerDialog();
    },
    [
      dispatch,
      handleCloseCreatePoolInnerDialog,
      listenerDetails?.protocol,
      matchParams.lbId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmEditPool = useCallback(
    (data: {
      name: string;
      description: string;
      algorithm: string;
      session_persistence?: SelectOption;
      cookie_name?: string;
      pool_admin_state_up: boolean;
    }) => {
      if (poolDetails) {
        dispatch(
          loadBalancersActions.updatePool.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            poolId: poolDetails.id,
            data: {
              name: data.name,
              description: data.description.trim(),
              lb_method: data.algorithm,
              persistence: data.session_persistence
                ? {
                    type:
                      data.session_persistence?.value === "None"
                        ? ""
                        : data.session_persistence?.value,
                    cookie_name: data.cookie_name
                  }
                : null,
              admin_state_up: data.pool_admin_state_up
            }
          })
        );
        handleCloseDialog();
      }
    },
    [
      poolDetails,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeletePool = useCallback(() => {
    if (poolDetails) {
      dispatch(
        loadBalancersActions.deletePool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          poolId: poolDetails.id
        })
      );
      handleCloseDialog();
    }
  }, [
    poolDetails,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    handleCloseDialog
  ]);

  const handleConfirmCreateHealthMonitor = useCallback(
    (data: {
      name: string;
      monitor_type: SelectOption;
      timeout: number;
      delay: number;
      max_retries: number;
      max_retries_down: number;
      http_method?: SelectOption;
      expected_codes: string;
      url_path: string;
    }) => {
      if (poolDetails) {
        dispatch(
          loadBalancersActions.createHealthMonitor.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: poolDetails.id,
            data: {
              name: data.name,
              type: data.monitor_type.value,
              delay: data.delay,
              timeout: data.timeout,
              max_retries: data.max_retries,
              max_retries_down: data.max_retries_down,
              http_method: data.http_method?.value,
              url_path: data.url_path,
              expected_codes: data.expected_codes,
              admin_state_up: true
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.projectId,
      matchParams.regionId,
      poolDetails
    ]
  );

  const handleConfirmEditHealthMonitor = useCallback(
    (data: {
      name?: string;
      timeout?: number;
      delay?: number;
      max_retries?: number;
      max_retries_down?: number;
      http_method?: SelectOption;
      expected_codes?: string;
      url_path?: string;
      monitor_admin_state_up?: boolean;
    }) => {
      if (poolDetails && monitorDetails) {
        dispatch(
          loadBalancersActions.updateHealthMonitor.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: poolDetails.id,
            monitorId: monitorDetails.id,
            data: {
              name: data.name,
              delay: data.delay,
              timeout: data.timeout,
              max_retries: data.max_retries,
              max_retries_down: data.max_retries_down,
              http_method: data.http_method?.value,
              url_path: data.url_path,
              expected_codes: data.expected_codes,
              admin_state_up: data.monitor_admin_state_up
            }
          })
        );
        handleCloseDialog();
      }
    },
    [
      poolDetails,
      monitorDetails,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteHealthMonitor = useCallback(() => {
    if (poolDetails && poolDetails.healthmonitor_id) {
      dispatch(
        loadBalancersActions.deleteHealthMonitor.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          poolId: poolDetails.id,
          monitorId: poolDetails.healthmonitor_id
        })
      );
      handleCloseDialog();
    }
  }, [
    poolDetails,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    handleCloseDialog
  ]);

  const handleConfirmCreateL7Policy = useCallback(
    (data: {
      name: string;
      action: string;
      redirect_pool_id?: SelectOption;
      redirect_url?: string;
      position?: number;
    }) => {
      dispatch(
        loadBalancersActions.createL7Policy.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          listenerId: matchParams.listenerId!,
          data: {
            name: data.name,
            action: data.action,
            redirect_pool: data.redirect_pool_id?.value,
            redirect_url: data.redirect_url,
            admin_state_up: true,
            position: data.position
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.listenerId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmEditL7Policy = useCallback(
    (data: {
      name?: string;
      description?: string;
      action?: string;
      redirect_pool_id?: SelectOption;
      redirect_url?: string;
      position?: number;
      policy_admin_state_up?: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          loadBalancersActions.updateL7Policy.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            listenerId: matchParams.listenerId!,
            l7PolicyId: selectedItemId,
            data: {
              name: data.name,
              description: data.description?.trim(),
              action: data.action,
              redirect_pool: data.redirect_pool_id?.value,
              redirect_url: data.redirect_url,
              position: data.position,
              admin_state_up: data.policy_admin_state_up
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.listenerId
    ]
  );

  const handleConfirmDeleteL7Policy = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.deleteL7Policy.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          listenerId: matchParams.listenerId!,
          l7PolicyId: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    selectedItemId,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.listenerId,
    handleCloseDialog
  ]);

  const handleConfirmAddPoolMember = useCallback(
    (data: {
      vm: SelectOption;
      subnet?: SelectOption;
      address: SelectOption; // vm
      port: number;
      weight: number;
      backup: boolean;
    }) => {
      if (listenerDetails && listenerDetails.default_pool_id) {
        dispatch(
          loadBalancersActions.addPoolMember.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: listenerDetails.default_pool_id,
            data: {
              name: data.vm.label,
              subnet_id: data.subnet?.value,
              address: data.address.value,
              protocol_port: data.port,
              weight: data.weight,
              backup: data.backup
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      listenerDetails,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmEditPoolMember = useCallback(
    (data: {
      name?: string;
      weight?: number;
      monitor_port?: number;
      monitor_address?: string;
      backup?: boolean;
      member_admin_state_up?: boolean;
    }) => {
      if (
        selectedItemId &&
        listenerDetails &&
        listenerDetails.default_pool_id
      ) {
        dispatch(
          loadBalancersActions.updatePoolMember.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            poolId: listenerDetails.default_pool_id,
            memberId: selectedItemId,
            data: {
              name: data.name,
              weight: data.weight,
              backup: data.backup,
              monitor_port: data.monitor_port,
              monitor_address:
                data.monitor_address === "" ? undefined : data.monitor_address,
              admin_state_up: data.member_admin_state_up
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      listenerDetails,
      matchParams.projectId,
      matchParams.regionId,
      selectedItemId
    ]
  );

  const handleConfirmRemovePoolMember = useCallback(() => {
    if (selectedItemId && listenerDetails && listenerDetails.default_pool_id) {
      dispatch(
        loadBalancersActions.removePoolMember.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          poolId: listenerDetails.default_pool_id,
          memberId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    listenerDetails,
    handleCloseDialog
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingL7PolicyName = tableL7Policies?.find(
    (policy) => policy.id === deletingItemId
  )?.name;

  const deletingMemberName = tablePoolMembers?.find(
    (member) => member.id === deletingItemId
  )?.name;

  const availablePoolProtocolOptions = filterAvailablePoolProtocols(
    listenerDetails?.protocol
  ).map((protocol) => ({
    label: protocol.label,
    value: protocol.value
  }));

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.ASSIGN_POOL]: {
      onConfirm: handleConfirmAssignPool,
      title: "Assign Pool",
      confirmButtonLabel: "Assign",
      fields: [
        {
          name: "default_pool",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Select Pool",
          helperText:
            "Only unassigned pools with a compatible protocol can be selected.",
          onCreate: handleCreatePoolInner,
          createText: "Create new pool",
          options: (() => {
            const currentPoolId = listenerDetails?.default_pool_id;
            const listenerProtocol = listenerDetails?.protocol;
            const compatiblePools = filterPoolsByListenerProtocol(
              pools || [],
              listenerProtocol
            );

            return compatiblePools
              ?.filter(
                (pool) =>
                  pool.listeners?.length === 0 || pool.id === currentPoolId
              )
              .map((pool) => ({
                label:
                  pool.id === currentPoolId
                    ? `${pool.name || pool.id} (current)`
                    : pool.name || pool.id,
                value: pool.id
              }));
          })(),

          // options: pools
          //   ?.filter((pool) => pool.listeners?.length === 0)
          //   .map((pool) => getSelectOption(pool, "id", "id")),
          defaultValue: (() => {
            const poolId = listenerDetails?.default_pool_id ?? undefined;
            if (!poolId) return undefined;
            const currentPool = pools?.find((pool) => pool.id === poolId);
            return currentPool
              ? {
                  label: `${currentPool.name || poolId} (current)`,
                  value: poolId
                }
              : undefined;
          })(),
          rules: selectOptionSchemaNotRequired.notRequired()
        }
      ]
    },
    [DIALOG_TYPES.CREATE_L7POLICY]: {
      onConfirm: handleConfirmCreateL7Policy,
      title: "Create L7Policy",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "position",
          type: FIELD_TYPES.NUMBER,
          label: "Position",
          defaultValue: 1,
          helperText: "The order in which the listener applies policy.",
          min: 1,
          rules: number()
            .integer()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .notRequired()
            .min(1, "The value must be a number greater than or equal to 1.")
        },
        {
          name: "action",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Action",
          helperText: `Set policy action to determine request flow:\n\nREJECT -  Blocks request with a response code.\nREDIRECT TO URL - Redirects to a specified URL.\nREDIRECT TO POOL - Redirects to specified pool.`,
          options: Object.keys(L7_POLICY_ACTION_LABELS).map((x) => ({
            label: L7_POLICY_ACTION_LABELS[x],
            value: x
          })),
          defaultValue: L7_POLICY_ACTION.REJECT,
          // dependent_fields: ["port"],
          rules: string()
        },
        {
          name: "redirect_url",
          type: FIELD_TYPES.TEXT,
          label: "Redirect URL",
          helperText:
            "Set the URL to which requests matching this policy will be redirected.",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_URL
            );
          },
          rules: string()
            .notRequired()
            .url("The redirect URL must be a valid HTTP or HTTPS URL.")
            .matches(
              /^https?:\/\/.+/i,
              "The redirect URL must be a valid HTTP or HTTPS URL and start with http:// or https://"
            )
        },
        {
          name: "redirect_pool_id",
          type: FIELD_TYPES.SELECT,
          label: "Redirect Pool",
          helperText:
            "Set the pool to which requests matching this policy will be redirected.",
          variant: "outlined",
          options: tablePools?.map((pool) => ({
            label: pool.name,
            value: pool.id
          })),
          // defaultValue: {
          //   label: LB_MONITOR_HTTP_METHODS_LABELS.GET,
          //   value: LB_MONITOR_HTTP_METHODS.GET
          // },
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_POOL
            );
          },
          rules: selectOptionSchemaNotRequired
        }
      ]
    },
    [DIALOG_TYPES.EDIT_L7POLICY]: {
      onConfirm: handleConfirmEditL7Policy,
      title: "Edit L7Policy",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            tableL7Policies?.find((l) => l.id === selectedItemId)?.name || "",
          rules: string().test({
            name: "validateName",
            test: validateName(ENTITY_NAME_LENGTH)
          })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            tableL7Policies?.find((l) => l.id === selectedItemId)
              ?.description || "",
          rules: string()
        },
        {
          name: "position",
          type: FIELD_TYPES.NUMBER,
          label: "Position",
          defaultValue:
            tableL7Policies?.find((l) => l.id === selectedItemId)?.position ||
            1,
          helperText: "The order in which the listener applies policy.",
          min: 1,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number greater than or equal to 1.")
        },
        {
          name: "action",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Action",
          helperText: `Set policy action to determine request flow:\n\nREJECT -  Blocks request with a response code.\nREDIRECT TO URL - Redirects to a specified URL.\nREDIRECT TO POOL - Redirects to specified pool.`,
          options: Object.keys(L7_POLICY_ACTION_LABELS).map((x) => ({
            label: L7_POLICY_ACTION_LABELS[x],
            value: x
          })),
          defaultValue:
            tableL7Policies?.find((l) => l.id === selectedItemId)?.action ||
            L7_POLICY_ACTION.REJECT,
          // dependent_fields: ["port"],
          rules: string()
        },
        {
          name: "redirect_url",
          type: FIELD_TYPES.TEXT,
          label: "Redirect URL",
          defaultValue:
            tableL7Policies?.find((l) => l.id === selectedItemId)
              ?.redirect_url || "",
          helperText:
            "Set the URL to which requests matching this policy will be redirected.",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_URL
            );
          },
          rules: string()
            .notRequired()
            .url("The redirect URL must be a valid HTTP or HTTPS URL.")
            .matches(
              /^https?:\/\/.+/i,
              "The redirect URL must be a valid HTTP or HTTPS URL and start with http:// or https://"
            )
        },
        {
          name: "redirect_pool_id",
          type: FIELD_TYPES.SELECT,
          label: "Redirect Pool",
          helperText:
            "Set the pool to which requests matching this policy will be redirected.",
          variant: "outlined",
          options: tablePools?.map((pool) => ({
            label: pool.name,
            value: pool.id
          })),
          defaultValue: tableL7Policies?.find((l) => l.id === selectedItemId)
            ?.redirect_url,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_POOL
            );
          },
          rules: selectOptionSchemaNotRequired
        },
        {
          name: "policy_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tableL7Policies?.find((l) => l.id === selectedItemId)
            ?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.CREATE_HEALTH_MONITOR]: {
      onConfirm: handleConfirmCreateHealthMonitor,
      title: "Create Health Monitor",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "monitor_type",
          type: FIELD_TYPES.SELECT,
          label: "Health Monitor Type",
          helperText:
            "Depends on the pool's protocol and defines how health checks are performed.",
          variant: "outlined",
          options: Object.keys(LB_MONITOR_TYPES_LABELS).map((x) => ({
            label: LB_MONITOR_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: {
            label: LB_MONITOR_TYPES_LABELS.HTTP,
            value: LB_MONITOR_TYPES.HTTP
          },
          rules: selectOptionSchema
        },
        {
          name: "timeout",
          type: FIELD_TYPES.NUMBER,
          helperText: "The time health check waits before failing.",
          defaultValue: 5,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, "The timeout must be a number greater than or equal to 0."),
          label: "Timeout"
        },
        {
          name: "delay",
          type: FIELD_TYPES.NUMBER,
          helperText: "The interval between health checks.",
          defaultValue: 5,
          fullWidth: false,
          width: "49%",
          margin: "0",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .test(
              "delay-greater-than-timeout",
              "The health check interval must be greater than or equal to the timeout.",
              function (delay) {
                const { timeout } = this.parent;
                if (delay == null || timeout == null) return true;
                return delay >= timeout;
              }
            ),
          label: "Delay"
        },
        {
          name: "max_retries",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member deactivation",
          defaultValue: 3,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Retries"
        },
        {
          name: "max_retries_down",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member error",
          defaultValue: 3,
          fullWidth: false,
          width: "49%",
          margin: "0",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Retries Down"
        },
        {
          name: "http_method",
          type: FIELD_TYPES.SELECT,
          label: "HTTP Method",
          helperText:
            "HTTP method for the health check. Active only when the monitor type is HTTP or HTTPS.",
          variant: "outlined",
          defaultValue: {
            label: LB_MONITOR_HTTP_METHODS_LABELS.GET,
            value: LB_MONITOR_HTTP_METHODS.GET
          },
          options: Object.keys(LB_MONITOR_HTTP_METHODS_LABELS).map((x) => ({
            label: LB_MONITOR_HTTP_METHODS_LABELS[x],
            value: x
          })),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: selectOptionSchemaNotRequired
        },
        {
          name: "expected_codes",
          type: FIELD_TYPES.TEXT,
          label: "Expected Codes",
          helperText:
            "Set the HTTP status codes that indicate a successful health check.",
          defaultValue: 200,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(
            /^\d+(-\d+)?(,\d+(-\d+)?)*$/,
            "Only numbers, ranges (e.g., 200-204), or comma-separated values are allowed"
          )
        },
        {
          name: "url_paths",
          type: FIELD_TYPES.TEXT,
          label: "URL Path",
          helperText: "Set target URL for the health check request.",
          defaultValue: "/",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(/^\/.*/, "URL Path must start with '/'")
        }
      ]
    },
    [DIALOG_TYPES.EDIT_HEALTH_MONITOR]: {
      onConfirm: handleConfirmEditHealthMonitor,
      title: "Edit Health Monitor",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: monitorDetails?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "timeout",
          type: FIELD_TYPES.NUMBER,
          helperText: "The time health check waits before failing.",
          defaultValue: monitorDetails?.timeout || 5,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, "The timeout must be a number greater than or equal to 0."),
          label: "Timeout"
        },
        {
          name: "delay",
          type: FIELD_TYPES.NUMBER,
          helperText: "The interval between health checks.",
          defaultValue: monitorDetails?.delay || 5,
          fullWidth: false,
          width: "49%",
          margin: "0",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .test(
              "delay-greater-than-timeout",
              "The health check interval must be greater than or equal to the timeout.",
              function (delay) {
                const { timeout } = this.parent;
                if (delay == null || timeout == null) return true;
                return delay >= timeout;
              }
            ),
          label: "Delay"
        },
        {
          name: "max_retries",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member deactivation",
          defaultValue: monitorDetails?.max_retries || 3,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Retries"
        },
        {
          name: "max_retries_down",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member error",
          defaultValue: monitorDetails?.max_retries_down || 3,
          fullWidth: false,
          width: "49%",
          margin: "0",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Retries Down"
        },
        {
          name: "http_method",
          type: FIELD_TYPES.SELECT,
          label: "HTTP Method",
          helperText:
            "HTTP method for the health check. Active only when the monitor type is HTTP or HTTPS.",
          variant: "outlined",
          options: Object.keys(LB_MONITOR_HTTP_METHODS_LABELS).map((x) => ({
            label: LB_MONITOR_HTTP_METHODS_LABELS[x],
            value: x
          })),
          defaultValue: (() => {
            return monitorDetails?.http_method
              ? {
                  label:
                    LB_MONITOR_HTTP_METHODS_LABELS[monitorDetails?.http_method],
                  value: monitorDetails?.http_method
                }
              : {
                  label:
                    LB_MONITOR_HTTP_METHODS_LABELS[LB_MONITOR_HTTP_METHODS.GET],
                  value: LB_MONITOR_HTTP_METHODS_LABELS.GET
                };
          })(),
          // defaultValue: {
          //   label: LB_MONITOR_HTTP_METHODS_LABELS.GET,
          //   value: LB_MONITOR_HTTP_METHODS.GET
          // },
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: selectOptionSchemaNotRequired
          // rules: string()
        },
        {
          name: "expected_codes",
          type: FIELD_TYPES.TEXT,
          label: "Expected Codes",
          helperText:
            "Set the HTTP status codes that indicate a successful health check.",
          defaultValue: monitorDetails?.expected_codes || 200,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(
            /^\d+(-\d+)?(,\d+(-\d+)?)*$/,
            "Only numbers, ranges (e.g., 200-204), or comma-separated values are allowed"
          )
        },
        {
          name: "url_paths",
          type: FIELD_TYPES.TEXT,
          label: "URL Path",
          helperText: "Set target URL for the health check request.",
          defaultValue: monitorDetails?.url_path || "/",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(/^\/.*/, "URL Path must start with '/'")
        },
        {
          name: "label4",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "monitor_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: monitorDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_LISTENER]: {
      onConfirm: handleConfirmEditListener,
      title: "Edit Listener",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: listenerDetails?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: listenerDetails?.description || "",
          rules: string()
        },
        {
          name: "connection_limit",
          type: FIELD_TYPES.NUMBER,
          defaultValue: listenerDetails?.connection_limit || -1,
          helperText:
            "Number of connections permitted for listener. Set -1 for unlimited connections.",
          min: -1,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              -1,
              "The connection limit must be a number greater than or equal to -1."
            ),
          label: "Connection Limit"
        },
        {
          name: "client_data_timeout",
          type: FIELD_TYPES.NUMBER,
          fullWidth: false,
          suffix: "ms",
          width: "33%",
          margin: "0.5%",
          defaultValue: listenerDetails?.timeout_client_data || 50000,
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: () => {
            return listenerDetails?.protocol === LB_PROTOCOLS.UDP;
          },
          label: "Client Data Timeout"
        },
        {
          name: "member_connection_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue: listenerDetails?.timeout_member_connect || 5000,
          fullWidth: false,
          width: "33%",
          margin: "0.5%",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return listenerDetails?.protocol === LB_PROTOCOLS.UDP;
          },
          label: "Member Connect Timeout"
        },
        {
          name: "member_data_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue: listenerDetails?.timeout_member_data || 50000,
          fullWidth: false,
          width: "33%",
          margin: "0px",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return listenerDetails?.protocol === LB_PROTOCOLS.UDP;
          },
          label: "Member Data Timeout"
        },
        {
          name: "headerInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: () => {
            return listenerDetails?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: `\n\nInsert Headers:`
        },
        {
          name: "x_forwarded_for",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: checkHeader("X-Forwarded-For") || false,
          isHidden: () => {
            return listenerDetails?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: "X-Forwarded-For"
        },
        {
          name: "x_forwarded_proto",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: checkHeader("X-Forwarded-Proto") || false,
          isHidden: () => {
            return listenerDetails?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: "X-Forwarded-Proto"
        },
        {
          name: "x_forwarded_port",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: checkHeader("X-Forwarded-Port") || false,
          isHidden: () => {
            return listenerDetails?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: "X-Forwarded-Port"
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "default_pool",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Default Pool",
          helperText:
            "Only unassigned pools with a compatible protocol can be selected.",
          onCreate: handleCreatePoolInner,
          createText: "Create new pool",
          options: (() => {
            const currentPoolId = listenerDetails?.default_pool_id;
            const listenerProtocol = listenerDetails?.protocol;
            const compatiblePools = filterPoolsByListenerProtocol(
              pools || [],
              listenerProtocol
            );

            return compatiblePools
              ?.filter(
                (pool) =>
                  pool.listeners?.length === 0 || pool.id === currentPoolId
              )
              .map((pool) => ({
                label:
                  pool.id === currentPoolId
                    ? `${pool.name || pool.id} (current)`
                    : pool.name || pool.id,
                value: pool.id
              }));
          })(),

          // options: pools
          //   ?.filter((pool) => pool.listeners?.length === 0)
          //   .map((pool) => getSelectOption(pool, "id", "id")),
          defaultValue: (() => {
            const poolId = listenerDetails?.default_pool_id ?? undefined;
            if (!poolId) return undefined;
            const currentPool = pools?.find((pool) => pool.id === poolId);
            return currentPool
              ? {
                  label: `${currentPool.name || poolId} (current)`,
                  value: poolId
                }
              : undefined;
          })(),
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "label0",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "listener_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: listenerDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_LB]: {
      onConfirm: handleConfirmEditLoadBalancer,
      title: "Edit Load Balancer",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: loadBalancerDetails?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: loadBalancerDetails?.description || "",
          rules: string()
        },
        {
          name: "lb_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: loadBalancerDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_POOL]: {
      onConfirm: handleConfirmEditPool,
      title: "Edit Pool",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: poolDetails?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: poolDetails?.description || "",
          rules: string()
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Pool Algorithm"
        },
        {
          name: "algorithm",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Algorithm",
          helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
          options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
            label: LB_POOL_ALGORITHM_LABELS[x],
            value: x
          })),
          defaultValue:
            poolDetails?.lb_algorithm || LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
          // dependent_fields: ["port"],
          rules: string()
        },
        {
          name: "session_persistence",
          type: FIELD_TYPES.SELECT,
          label: "Session Persistence",
          variant: "outlined",
          helperText:
            "Type of session persistence for routing traffic to pool members.",
          options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map((x) => ({
            label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
            value: x
          })),
          defaultValue:
            poolDetails?.session_persistence?.type &&
            poolDetails?.session_persistence?.type !== ""
              ? {
                  label: poolDetails?.session_persistence.type,
                  value: poolDetails?.session_persistence.type
                }
              : {
                  label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
                  value: LB_POOL_SESSION_PERSISTENCE.None
                },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "cookie_name",
          type: FIELD_TYPES.TEXT,
          label: "Cookie Name",
          defaultValue: poolDetails?.session_persistence?.cookie_name || "",
          isHidden: (fieldValues) => {
            const persistence =
              fieldValues.session_persistence as SelectOption | null;

            return (
              !persistence ||
              persistence.value !==
                String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
            );
          },
          rules: string()
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "pool_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: poolDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.ADD_POOL_MEMBER]: {
      onConfirm: handleConfirmAddPoolMember,
      title: "Add Pool Member",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "vm",
          type: FIELD_TYPES.SELECT,
          label: "Virtual Machine",
          options: virtualMachines?.map((vm) =>
            getSelectOption(vm, "name", "id")
          ),
          dependent_fields: ["address"],
          rules: selectOptionSchema
        },
        {
          name: "address",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "IP Address",
          options: (fieldValues) => {
            if (!isSelectOption(fieldValues.vm)) return [];

            const selectedVmId = fieldValues.vm.value;

            return interfaces
              ?.filter((intf) => intf.device_id === selectedVmId)
              .flatMap((intf) =>
                intf.fixed_ips.map((ip) =>
                  getSelectOption(ip, "ip_address", "ip_address")
                )
              );
          },
          isHidden: (fieldValues) => {
            return !fieldValues.vm;
          },
          rules: selectOptionSchema
        },
        {
          name: "subnet",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Subnet",
          // options: subnets?.map((subnet) =>
          //   getSelectOption(subnet, "name", "id")
          // ),
          options: (fieldValues) => {
            const selectedIp = isSelectOption(fieldValues.address)
              ? fieldValues.address.value
              : null;

            // find subnet of the selected ip
            const selectedIpSubnetId =
              interfaces
                ?.flatMap((intf) =>
                  intf.fixed_ips.filter((ip) => ip.ip_address === selectedIp)
                )
                ?.map((ip) => ip.subnet_id)[0] || null;

            return subnets?.map((subnet) => {
              let suffix = "";

              // set (vm) sufix
              if (selectedIp && subnet.id === selectedIpSubnetId)
                suffix = " (vm)";

              // set (lb) sufix
              if (subnet.id === loadBalancerDetails?.vip_subnet_id)
                suffix += " (lb)";

              return getSelectOption(
                { ...subnet, name: `${subnet.name}${suffix}` },
                "name",
                "id"
              );
            });
          },
          helperText:
            "Set subnet member service is accessible from. If omitted, parent lb's subnet will be used.",
          rules: selectOptionSchemaNotRequired.notRequired()
        },

        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          label: "Protocol Port",
          helperText: "Set protocol port for the resource.",
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
        },
        {
          name: "weight",
          type: FIELD_TYPES.NUMBER,
          label: "Weight",
          helperText:
            "Set how much load this member should take. Range: 1-256.",
          defaultValue: 1,
          min: 1,
          max: 256,
          rules: number()
            .required()
            .integer()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "Must be a number from 1 to 256.")
            .max(256, "Must be a number from 1 to 256.")
        },
        {
          name: "backup",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: false,
          label: "Backup Member"
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          isHidden: (fieldValues) => !fieldValues.backup,
          label:
            "ℹ️ Backup Member will receive traffic only if all primary members fail."
        }
      ]
    },
    [DIALOG_TYPES.EDIT_POOL_MEMBER]: {
      onConfirm: handleConfirmEditPoolMember,
      title: "Edit Pool Member",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            tablePoolMembers?.find((l) => l.id === selectedItemId)?.name || "",
          rules: string().test({
            name: "validateName",
            test: validateName(ENTITY_NAME_LENGTH)
          })
        },
        {
          name: "weight",
          type: FIELD_TYPES.NUMBER,
          label: "Weight",
          helperText:
            "Set how much load this member should take. Range: 1-256.",
          defaultValue:
            tablePoolMembers?.find((l) => l.id === selectedItemId)?.weight || 1,
          min: 1,
          max: 256,
          rules: number()
            .required()
            .integer()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "Must be a number from 1 to 256.")
            .max(256, "Must be a number from 1 to 256.")
        },
        {
          name: "monitor_port",
          type: FIELD_TYPES.NUMBER,
          defaultValue: tablePoolMembers?.find((l) => l.id === selectedItemId)
            ?.monitor_port,
          label: "Monitor Port",
          helperText:
            "Optional port for health checks. If not set, the member's protocol port is used.",
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .nullable()
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
        },
        {
          name: "monitor_address",
          type: FIELD_TYPES.TEXT,
          variant: "outlined",
          helperText:
            "Optional IP address for health checks. If not set, the member's IP is used.",
          label: "Monitor Address",
          defaultValue:
            tablePoolMembers?.find((l) => l.id === selectedItemId)
              ?.monitor_address || "",
          rules: string()
            .trim()
            .nullable()
            .notRequired()
            .matches(
              /^$|^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.|$)){4}$/,
              "Enter a valid IPv4 address"
            )
        },
        {
          name: "member_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tablePoolMembers?.find((l) => l.id === selectedItemId)
            ?.admin_state_up,
          label: "Admin State"
        },
        {
          name: "backup",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tablePoolMembers?.find((l) => l.id === selectedItemId)
            ?.backup,
          label: "Backup Member"
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          isHidden: (fieldValues) => !fieldValues.backup,
          label:
            "ℹ️ Backup Member will receive traffic only if all primary members fail."
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_POOL_MEMBER]: {
      onConfirm: handleConfirmRemovePoolMember,
      title: `Are you sure you want to remove "${deletingMemberName ?? "selected"}" pool member?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_L7POLICY]: {
      onConfirm: handleConfirmDeleteL7Policy,
      title: `Are you sure you want to delete "${deletingL7PolicyName ?? "selected"}" L7Policy?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_LISTENER]: {
      onConfirm: handleConfirmDeleteListener,
      title: `Are you sure you want to delete current listener?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_HEALTH_MONITOR]: {
      onConfirm: handleConfirmDeleteHealthMonitor,
      title: `Are you sure you want to delete "${monitorDetails?.id ?? "selected"}" health monitor?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_POOL]: {
      onConfirm: handleConfirmDeletePool,
      title: `Are you sure you want to delete "${poolDetails?.name ?? "selected"}" pool?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.UNASSIGN_POOL]: {
      onConfirm: handleConfirmUnassignPool,
      title: `Are you sure you want to unassign pool "${poolDetails?.name ?? "selected"}" from the current listener?`,
      confirmButtonLabel: "Unassign"
    }
  };

  return (
    <>
      <Head title={title} />
      {listenerDetails ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>Listener ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {listenerDetails?.id}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              {/* <s.SummaryRow>
                <s.DetailsTitle>Assigned Pool ID: </s.DetailsTitle>
                <s.DetailsInfo>{listenerDetails.default_pool_id}</s.DetailsInfo>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Assigned Health Monitor ID: </s.DetailsTitle>
                <s.DetailsInfo>{poolDetails?.healthmonitor_id}</s.DetailsInfo>
              </s.SummaryRow> */}
              <s.SummaryRow>
                <s.DetailsTitle>Protocol:</s.DetailsTitle>
                <s.Tag label={listenerDetails.protocol} />
                <s.DetailsTitle>Port:</s.DetailsTitle>
                <s.Tag label={listenerDetails.protocol_port} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Provisioning Status:</s.DetailsTitle>
                <s.Tag label={listenerDetails.provisioning_status} />
                <s.DetailsTitle>Admin State:</s.DetailsTitle>
                <s.Tag label={listenerDetails.admin_state_up ? "up" : "down"} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Connection Limit:</s.DetailsTitle>
                <s.Tag
                  label={
                    listenerDetails.connection_limit === -1
                      ? "Unlimited"
                      : listenerDetails.connection_limit
                  }
                />
                <s.DetailsTitle>Client Data Timeout:</s.DetailsTitle>
                <s.Tag label={listenerDetails.timeout_client_data} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Member Connect Timeout :</s.DetailsTitle>
                <s.Tag label={listenerDetails.timeout_member_connect} />
                <s.DetailsTitle>Member Data Timeout :</s.DetailsTitle>
                <s.Tag label={listenerDetails.timeout_member_data} />
              </s.SummaryRow>

              {listenerDetails.protocol === String(LB_PROTOCOLS.HTTP) && (
                <s.SummaryRow>
                  <s.DetailsTitle>Inserted Headers:</s.DetailsTitle>

                  {listenerDetails.insert_headers &&
                  Object.values(listenerDetails.insert_headers).some(
                    (v) => v === "True"
                  ) ? (
                    <>
                      {checkHeader("X-Forwarded-For") && (
                        <s.Tag label={"X-Forwarded-For"} />
                      )}
                      {checkHeader("X-Forwarded-Proto") && (
                        <s.Tag label={"X-Forwarded-Proto"} />
                      )}
                      {checkHeader("X-Forwarded-Port") && (
                        <s.Tag label={"X-Forwarded-Port"} />
                      )}
                    </>
                  ) : (
                    <s.Tag label="None" />
                  )}
                </s.SummaryRow>
              )}

              {listenerDetails.description && (
                <s.SummaryRow>
                  <s.DetailsTitle>Description:</s.DetailsTitle>
                  <s.DetailsInfo>{listenerDetails.description}</s.DetailsInfo>
                </s.SummaryRow>
              )}
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditListenerButtonClick}
                    color={"inherit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteListenerButtonClick}
                    color={"inherit"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.InfoContainer variant="outlined">
            <Box display="flex" flexWrap="wrap" gap={4}>
              {loadBalancerDetails ? (
                <Box flexBasis="30%" minWidth="200px" flexGrow={3}>
                  {/* <s.SectionTitle>Parent Load Balancer</s.SectionTitle> */}
                  <s.ComponentTitleContainer>
                    <s.ComponentTitle>Parent Load Balancer</s.ComponentTitle>
                    <Tooltip title={"Edit"} arrow placement="top">
                      <span>
                        <IconButton
                          onClick={handleEditLoadBalancerButtonClick}
                          color="primary"
                          size="small"
                        >
                          <QuotaEditIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </s.ComponentTitleContainer>
                  <s.InfoItem>
                    <s.InfoLabel>ID:</s.InfoLabel>
                    <s.InfoValue>{loadBalancerDetails.id}</s.InfoValue>
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Name:</s.InfoLabel>
                    <s.InfoValueLink
                      to={generateLoadBalancersTableItemURL(
                        loadBalancerDetails.id
                      )}
                    >
                      {loadBalancerDetails.name}
                    </s.InfoValueLink>
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Provisioning Status:</s.InfoLabel>
                    <s.Tag label={loadBalancerDetails.provisioning_status} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Admin State:</s.InfoLabel>
                    <s.Tag
                      label={loadBalancerDetails.admin_state_up ? "up" : "down"}
                    />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>IP:</s.InfoLabel>
                    <s.Tag label={loadBalancerDetails.vip_address} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Floating IP:</s.InfoLabel>
                    {loadBalancerDetails?.floating_ip ? (
                      <s.Tag label={loadBalancerDetails.floating_ip} />
                    ) : (
                      <s.Tag label={"None"} />
                    )}
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Subnet Name:</s.InfoLabel>
                    <s.InfoValue>{loadBalancerDetails.subnet_name}</s.InfoValue>
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Network Name:</s.InfoLabel>
                    <s.InfoValue>
                      {loadBalancerDetails.network_name}
                    </s.InfoValue>
                  </s.InfoItem>
                  {loadBalancerDetails.description && (
                    <s.InfoItem>
                      <s.InfoLabel>Description:</s.InfoLabel>
                      <s.InfoValue>
                        {loadBalancerDetails.description}
                      </s.InfoValue>
                    </s.InfoItem>
                  )}
                </Box>
              ) : (
                <Box
                  flexBasis="30%"
                  minWidth="200px"
                  flexGrow={2}
                  minHeight="200px"
                >
                  <s.SectionTitleCentered>
                    Parent Load Balancer
                  </s.SectionTitleCentered>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    sx={{ marginLeft: "85px", marginTop: "70px" }}
                  >
                    <Loader />
                  </Box>
                </Box>
              )}
              {poolDetails ? (
                <Box
                  flexBasis="30%"
                  minWidth="200px"
                  flexGrow={poolDetails?.healthmonitor_id ? 1 : 3}
                >
                  {/* <s.SectionTitle>Assigned Pool</s.SectionTitle> */}
                  <s.ComponentTitleContainer>
                    <s.ComponentTitle>Assigned Pool</s.ComponentTitle>
                    <Tooltip title={"Edit"} arrow placement="top">
                      <span>
                        <IconButton
                          onClick={handleEditPoolMenuItemClick}
                          color="primary"
                          size="small"
                        >
                          <QuotaEditIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={"Remove"} arrow placement="top">
                      <span>
                        <IconButton
                          // onClick={handleDeletePoolButtonClick}
                          onClick={handleDeleteMenuButtonClick}
                          ref={deleteButtonRef}
                          color={"primary"}
                          size="small"
                        >
                          <DeleteSweepRoundedIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Menu
                      isOpened={isDeleteMenuOpen}
                      onClose={handleDeleteMenuClose}
                      anchorEl={deleteButtonRef.current}
                      items={[
                        {
                          label: "Unassign",
                          onClick: handleDeletePoolMenuClick(
                            DELETE_DIALOG_TYPE.UNASSIGN_POOL
                          )
                        },
                        {
                          label: "Delete",
                          onClick: handleDeletePoolMenuClick(
                            DELETE_DIALOG_TYPE.DELETE_POOL
                          )
                        }
                      ]}
                    />
                  </s.ComponentTitleContainer>
                  <s.InfoItem>
                    <s.InfoLabel>ID:</s.InfoLabel>
                    <s.InfoValue>{poolDetails.id}</s.InfoValue>
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Name:</s.InfoLabel>
                    <s.InfoValueLink
                      to={generatePoolsTableItemURL(poolDetails.id)}
                    >
                      {poolDetails.name}
                    </s.InfoValueLink>
                  </s.InfoItem>
                  {/* <s.InfoItem>
                    <s.InfoLabel>Operating Status:</s.InfoLabel>
                    <s.Tag label={poolDetails.operating_status} />
                  </s.InfoItem> */}
                  <s.InfoItem>
                    <s.InfoLabel>Provisioning Status:</s.InfoLabel>
                    <s.Tag label={poolDetails.provisioning_status} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Admin State:</s.InfoLabel>
                    <s.Tag label={poolDetails.admin_state_up ? "up" : "down"} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Protocol:</s.InfoLabel>
                    <s.Tag label={poolDetails.protocol} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Algorithm:</s.InfoLabel>
                    <s.Tag label={poolDetails?.lb_algorithm} />
                  </s.InfoItem>
                  <s.InfoItem>
                    <s.InfoLabel>Session Persistence:</s.InfoLabel>
                    {poolDetails.session_persistence &&
                    poolDetails.session_persistence.type !== "" ? (
                      <s.Tag label={poolDetails.session_persistence.type} />
                    ) : (
                      <s.Tag label={"None"} />
                    )}
                  </s.InfoItem>
                  {Boolean(poolDetails.session_persistence?.cookie_name) && (
                    <s.InfoItem>
                      <s.InfoLabel>Cookie Name:</s.InfoLabel>
                      <s.Tag
                        label={poolDetails.session_persistence?.cookie_name}
                      />
                    </s.InfoItem>
                  )}
                  {poolDetails.description && (
                    <s.InfoItem>
                      <s.InfoLabel>Description:</s.InfoLabel>
                      <s.InfoValue>{poolDetails.description}</s.InfoValue>
                    </s.InfoItem>
                  )}
                </Box>
              ) : (
                <Box
                  flexBasis="30%"
                  minHeight="200px"
                  minWidth="200px"
                  flexGrow={3}
                >
                  <s.SectionTitleCentered2>
                    Assigned Pool
                  </s.SectionTitleCentered2>
                  {listenerDetails?.default_pool_id ? (
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      sx={{ marginLeft: "30px", marginTop: "70px" }}
                    >
                      <Loader />
                    </Box>
                  ) : (
                    <Button
                      onClick={handleAssignPoolButtonClick}
                      variant="contained"
                      disabled={
                        isPoolCreating || isListenerUpdating || isPoolLoading
                      }
                    >
                      Assign Pool
                    </Button>
                  )}
                </Box>
              )}

              {poolDetails ? (
                monitorDetails ? (
                  <Box flexBasis="30%" minWidth="200px" flexGrow={1}>
                    {/* <s.SectionTitle>Assigned Health Monitor</s.SectionTitle> */}
                    <s.ComponentTitleContainer>
                      <s.ComponentTitle>Health Monitor</s.ComponentTitle>
                      <Tooltip title={"Edit"} arrow placement="top">
                        <span>
                          <IconButton
                            onClick={handleEditHealthMonitorMenuItemClick}
                            color={"primary"}
                            size="small"
                          >
                            <QuotaEditIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={"Delete"} arrow placement="top">
                        <span>
                          <IconButton
                            onClick={handleDeleteHealthMonitorMenuItemClick}
                            color={"primary"}
                            size="small"
                          >
                            <DeleteSweepRoundedIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </s.ComponentTitleContainer>
                    <s.InfoItem>
                      <s.InfoLabel>ID:</s.InfoLabel>
                      <s.InfoValue>{monitorDetails.id}</s.InfoValue>
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Name:</s.InfoLabel>
                      <s.InfoValue>{monitorDetails.name}</s.InfoValue>
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Provisioning Status:</s.InfoLabel>
                      <s.Tag label={monitorDetails.provisioning_status} />
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Admin State:</s.InfoLabel>
                      <s.Tag
                        label={monitorDetails.admin_state_up ? "up" : "down"}
                      />
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Type:</s.InfoLabel>
                      <s.Tag label={monitorDetails.type} />
                      {monitorDetails.http_method && (
                        <>
                          <s.InfoLabel>HTTP Method:</s.InfoLabel>
                          <s.Tag label={monitorDetails.http_method} />
                        </>
                      )}
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Timeout:</s.InfoLabel>
                      <s.Tag label={monitorDetails.timeout} />
                      <s.InfoLabel>Delay:</s.InfoLabel>
                      <s.Tag label={monitorDetails.delay} />
                    </s.InfoItem>
                    <s.InfoItem>
                      <s.InfoLabel>Retries:</s.InfoLabel>
                      <s.Tag label={monitorDetails.max_retries} />
                      <s.InfoLabel>Retries Down:</s.InfoLabel>
                      <s.Tag label={monitorDetails.max_retries_down} />
                    </s.InfoItem>

                    {monitorDetails.url_path && (
                      <s.InfoItem>
                        <s.InfoLabel>URL Path:</s.InfoLabel>
                        <s.InfoValue>{monitorDetails.url_path}</s.InfoValue>
                      </s.InfoItem>
                    )}
                    {monitorDetails.expected_codes && (
                      <s.InfoItem>
                        <s.InfoLabel>Expected Codes :</s.InfoLabel>
                        <s.InfoValue>
                          {monitorDetails.expected_codes}
                        </s.InfoValue>
                      </s.InfoItem>
                    )}
                  </Box>
                ) : (
                  <Box
                    flexBasis="30%"
                    minHeight="200px"
                    minWidth="200px"
                    flexGrow={1}
                  >
                    <s.SectionTitleCentered>
                      Health Monitor
                    </s.SectionTitleCentered>
                    {poolDetails?.healthmonitor_id ? (
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        sx={{ marginLeft: "70px", marginTop: "70px" }}
                      >
                        <Loader />
                      </Box>
                    ) : (
                      <Button
                        onClick={handleCreateHealthMonitorButtonClick}
                        variant="contained"
                        disabled={
                          isMonitorCreating ||
                          isPoolCreating ||
                          isMonitorLoading
                        }
                      >
                        Create Health Monitor
                      </Button>
                    )}
                  </Box>
                )
              ) : null}
            </Box>
          </s.InfoContainer>

          {/* <Table
            key={"l7PolicyTable"}
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={tableL7Policies || []}
            columns={tableL7PoliciesColumns}
            actions={tableL7PoliciesActions}
            itemLink={{
              column: "name",
              getURL: generateL7PoliciesTableItemURL
            }}
            isLoading={!tableL7Policies}
            toolbarItems={
              <Button
                onClick={handleCreateL7PolicyButtonClick}
                variant={"contained"}
              >
                Create L7Policy
              </Button>
            }
          /> */}
          {poolDetails && (
            <Table
              key={"poolMembersTable"}
              isSearchEnabled={true}
              isSortingEnabled={true}
              rows={tablePoolMembers || []}
              columns={tablePoolMembersColumns}
              actions={tablePoolMembersActions}
              isLoading={!tablePoolMembers}
              toolbarItems={
                <Button
                  onClick={handleAddPoolMemberButtonClick}
                  variant={"contained"}
                  disabled={!virtualMachines || !interfaces || !subnets}
                >
                  Add Pool Member
                </Button>
              }
            />
          )}

          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
          <FormDialog
            isOpened={isCreatePoolInnerDialogOpened}
            onCancel={handleCloseCreatePoolInnerDialog}
            fields={[
              {
                name: "name",
                type: FIELD_TYPES.TEXT,
                label: "Name",
                rules: string()
                  .required()
                  .test({
                    name: "validateName",
                    test: validateName(ENTITY_NAME_LENGTH)
                  })
              },
              {
                name: "pool_protocol",
                type: FIELD_TYPES.SELECT,
                variant: "outlined",
                label: "Pool Protocol",
                helperText: "Choose a compatible with listener pool protocol.",
                options: availablePoolProtocolOptions,
                defaultValue: listenerDetails.protocol
                  ? {
                      label: listenerDetails.protocol,
                      value: listenerDetails.protocol
                    }
                  : undefined,
                rules: selectOptionSchema
              },
              {
                name: "divider",
                type: FIELD_TYPES.DIVIDER,
                label: "Pool Algorithm"
              },
              {
                name: "algorithm",
                type: FIELD_TYPES.TOGGLE_BUTTON,
                label: "Algorithm",
                helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
                options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
                  label: LB_POOL_ALGORITHM_LABELS[x],
                  value: x
                })),
                defaultValue: LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
                rules: string()
              },
              {
                name: "session_persistence",
                type: FIELD_TYPES.SELECT,
                label: "Session Persistence",
                variant: "outlined",
                helperText:
                  "Type of session persistence for routing traffic to pool members.",
                options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map(
                  (x) => ({
                    label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
                    value: x
                  })
                ),
                defaultValue: {
                  label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
                  value: LB_POOL_SESSION_PERSISTENCE.None
                },
                rules: selectOptionSchemaNotRequired.notRequired()
              },
              {
                name: "cookie_name",
                type: FIELD_TYPES.TEXT,
                label: "Cookie Name",
                isHidden: (fieldValues) => {
                  const persistence =
                    fieldValues.session_persistence as SelectOption | null;

                  return (
                    !persistence ||
                    persistence.value !==
                      String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
                  );
                },
                rules: string()
              }
            ]}
            onConfirm={handleConfirmCreatePoolInner}
            title={"Create Pool"}
            confirmButtonLabel={"Create"}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
