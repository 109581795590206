import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import {
  FormDialog,
  selectOptionSchema,
  selectOptionSchemaNotRequired
} from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import * as loadBalancersActions from "modules/loadBalancers/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  areSubnetsLoadingSelector,
  floatingIPsSelector,
  isFloatingIPUpdatingSelector,
  subnetsSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  MAX_PORT,
  MIN_PORT,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES, TABS } from "./types";
import {
  LB_MONITOR_HTTP_METHODS,
  LB_MONITOR_HTTP_METHODS_LABELS,
  LB_MONITOR_TYPES,
  LB_MONITOR_TYPES_LABELS,
  LB_POOL_ALGORITHM,
  LB_POOL_ALGORITHM_LABELS,
  LB_POOL_SESSION_PERSISTENCE,
  LB_POOL_SESSION_PERSISTENCE_LABELS,
  LB_PROTOCOLS,
  LB_PROTOCOLS_LABELS,
  TableLoadBalancer
} from "modules/loadBalancers/types";
import {
  areLoadBalancersLoadingSelector,
  isLoadBalancerCreatingSelector,
  isLoadBalancerDeletingSelector,
  isLoadBalancerUpdatingSelector,
  tableLoadBalancersSelector
} from "modules/loadBalancers/selectors";
import { getSelectOption } from "utils/getSelectOption";
import { Badge } from "@mui/material";

const POLL_ID_PREFIX = "LOAD_BALANCERS";

const POLL_IDS = {
  loadBalancers: "LOAD_BALANCERS",
  interfaces: "INTERFACES",
  floating_ips: "FLOATING_IPS",
  subnets: "SUBNETS"
};

const title = "Load Balancers";

const tableLoadBalancersColumns: TableColumn<TableLoadBalancer>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "ips_str", label: "IP Addresses" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
];

export const LoadBalancers: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const subnets = useSelector(subnetsSelector);
  const floatingIPs = useSelector(floatingIPsSelector);
  const isFloatingIPUpdating = useSelector(isFloatingIPUpdatingSelector);

  const tableLoadBalancers = useSelector(tableLoadBalancersSelector);

  const isLoadBalancerCreating = useSelector(isLoadBalancerCreatingSelector);
  const isLoadBalancerUpdating = useSelector(isLoadBalancerUpdatingSelector);
  const isLoadBalancersDeleting = useSelector(isLoadBalancerDeletingSelector);

  const isLoadBalancersOperationInProgress =
    isLoadBalancerCreating ||
    isLoadBalancerUpdating ||
    isLoadBalancersDeleting ||
    isFloatingIPUpdating;

  const previousIsLoadBalancersOperationInProgress = usePrevious(
    isLoadBalancersOperationInProgress
  );

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_LOAD_BALANCER, isOpened: false });

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const generateLoadBalancersTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: id
      }),
    [matchParams.organizationId, matchParams.projectId, matchParams.regionId]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "LoadBalancers",
      url: generatePath(ROUTES.LOAD_BALANCERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const handleCreateLoadBalancerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_LOAD_BALANCER,
      isOpened: true
    });
  }, []);

  const handleEditLoadBalancerMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_LOAD_BALANCER,
      isOpened: true
    });
  }, []);

  const handleDeleteLoadBalancerMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_LOAD_BALANCER,
      isOpened: true
    });
  }, []);

  const handleAssociateFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDisassociateFloatingIPMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.DISASSOCIATE_FLOATING_IP,
        isOpened: true
      });
    },
    []
  );

  const tableLoadBalancersActions: TableRowActionsMenuItem<TableLoadBalancer>[] =
    [
      {
        label: "Associate Floating IP",
        handler: handleAssociateFloatingIPMenuItemClick,
        isDisabled: (tableLB) => tableLB.ips_str.includes(",")
      },
      {
        label: "Dissociate Floating IP",
        handler: handleDisassociateFloatingIPMenuItemClick,
        isDisabled: (tableLB) => !tableLB.ips_str.includes(",")
      },
      {
        label: "Edit",
        handler: handleEditLoadBalancerMenuItemClick
      },
      {
        label: "Delete",
        handler: handleDeleteLoadBalancerMenuItemClick
      }
    ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.subnets}`,
        action: networksActions.getSubnets.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.floating_ips}`,
        action: networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.loadBalancers}`,
        action: loadBalancersActions.listLoadBalancers.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      loadBalancersActions.listLoadBalancers.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
    dispatch(loadBalancersActions.clear());
  });

  useEffect(() => {
    if (
      previousIsLoadBalancersOperationInProgress &&
      !isLoadBalancersOperationInProgress
    ) {
      dispatch(
        loadBalancersActions.listLoadBalancers.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsLoadBalancersOperationInProgress,
    isLoadBalancersOperationInProgress
  ]);

  const handleConfirmCreateLoadBalancer = useCallback(
    (data: {
      name: string;
      subnets: SelectOption;
      protocol: string;
      port: number;
      connection_limit: number;
      // client_data_timeout?: number;
      // member_connection_timeout?: number;
      // member_data_timeout?: number;
      x_forwarded_for?: boolean;
      x_forwarded_proto?: boolean;
      x_forwarded_port?: boolean;
      algorithm: string;
      session_persistence?: SelectOption;
      cookie_name?: string;
      monitor_type: SelectOption;
      timeout: number;
      delay: number;
      max_retries: number;
      max_retries_down: number;
      http_method: SelectOption;
      expected_codes: string;
      url_path: string;
    }) => {
      const rawHeaders = {
        "X-Forwarded-For": data.x_forwarded_for,
        "X-Forwarded-Proto": data.x_forwarded_proto,
        "X-Forwarded-Port": data.x_forwarded_port
      };

      const insertHeaders = Object.entries(rawHeaders).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value ? "True" : "False";
          }
          return acc;
        },
        {} as Record<string, string>
      );

      dispatch(
        loadBalancersActions.createLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            name: data.name,
            description: "",
            subnet_id: data.subnets.value,
            admin_state_up: true,
            listener: {
              name: data.name + "-listener",
              protocol: data.protocol,
              protocol_port: data.port,
              connection_limit: data.connection_limit,
              // timeout_client_data: data.client_data_timeout || 50000,
              // timeout_member_connect: data.member_connection_timeout || 5000,
              // timeout_member_data: data.member_data_timeout || 50000,
              admin_state_up: true,
              insert_headers:
                Object.keys(insertHeaders).length > 0
                  ? insertHeaders
                  : undefined,
              pool: {
                name: data.name + "-pool",
                protocol: data.protocol,
                lb_method: data.algorithm,
                persistence: data.session_persistence
                  ? {
                      type:
                        data.session_persistence?.value === "None"
                          ? ""
                          : data.session_persistence?.value,
                      cookie_name: data.cookie_name
                    }
                  : null,
                admin_state_up: true,
                monitor: {
                  name: data.name + "-monitor",
                  type: data.monitor_type.value,
                  delay: data.delay,
                  timeout: data.timeout,
                  max_retries: data.max_retries,
                  max_retries_down: data.max_retries_down,
                  http_method: data.http_method?.value,
                  url_path: data.url_path,
                  expected_codes: data.expected_codes,
                  admin_state_up: true
                }
              }
            }
          }
        })
      );

      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, matchParams.projectId, matchParams.regionId]
  );

  const handleConfirmEditLoadBalancer = useCallback(
    (data: {
      name: string;
      description: string;
      lb_admin_state_up: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          loadBalancersActions.updateLoadBalancer.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: selectedItemId,
            data: {
              name: data.name,
              description: data.description.trim(),
              admin_state_up: data.lb_admin_state_up
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteLoadBalancer = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.deleteLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmAssociateFloatingIP = useCallback(
    (data: { interface: SelectOption; floatingIP: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: data.floatingIP.value,
            data: {
              ...data,
              port_id: tableLoadBalancers?.find(
                (lb) => lb.id === selectedItemId
              )?.vip_port_id
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      tableLoadBalancers
    ]
  );

  const handleConfirmDisassociateFloatingIP = useCallback(() => {
    if (selectedItemId && floatingIPs && tableLoadBalancers) {
      const loadBalancerFloatingIp = tableLoadBalancers.find(
        (lb) => lb.id === selectedItemId
      )?.floating_ip;

      const floatingIpId = floatingIPs?.find(
        (fip) => fip.floating_ip_address === loadBalancerFloatingIp
      )?.id;
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: floatingIpId || "",
          data: {
            port_id: ""
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    floatingIPs,
    tableLoadBalancers,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingLoadBalancerName = tableLoadBalancers?.find(
    (lb) => lb.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_LOAD_BALANCER]: {
      onConfirm: handleConfirmCreateLoadBalancer,
      title: "Create Load Balancer",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "subnets",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Subnets",
          options: subnets?.map((subnet) =>
            getSelectOption(subnet, "name", "id")
          ),
          rules: selectOptionSchema
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Listener Details"
        },
        {
          name: "protocol",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Protocol",
          helperText: "Choose Listener Protocol",
          options: Object.keys(LB_PROTOCOLS_LABELS).map((x) => ({
            label: LB_PROTOCOLS_LABELS[x],
            value: x
          })),
          defaultValue: LB_PROTOCOLS.TCP,
          rules: string()
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`),
          label: "Port"
        },
        {
          name: "connection_limit",
          type: FIELD_TYPES.NUMBER,
          helperText:
            "Number of connections permitted for listener. Set -1 for unlimited connections.",
          defaultValue: -1,
          min: -1,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              -1,
              "The connection limit must be a number greater than or equal to -1."
            ),
          label: "Connection Limit"
        },
        // {
        //   name: "client_data_timeout",
        //   type: FIELD_TYPES.NUMBER,
        //   fullWidth: false,
        //   suffix: "ms",
        //   width: "33%",
        //   margin: "0.5%",
        //   defaultValue: 50000,
        //   min: 0,
        //   max: MAX_LB_TIMEOUT,
        //   rules: number()
        //     .integer()
        //     .nullable()
        //     .transform(
        //       (value: number | null, originalValue: string | number) => {
        //         if (
        //           typeof originalValue === "string" &&
        //           originalValue.trim() === ""
        //         ) {
        //           return null;
        //         }
        //         return typeof value === "number" ? value : null;
        //       }
        //     )
        //     .min(0, ERROR_MESSAGES.LB_TIMEOUT)
        //     .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
        //   isHidden: (fieldValues) => {
        //     return (
        //       !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
        //     );
        //   },
        //   label: "Client Data Timeout"
        // },
        // {
        //   name: "member_connection_timeout",
        //   type: FIELD_TYPES.NUMBER,
        //   defaultValue: 5000,
        //   fullWidth: false,
        //   width: "33%",
        //   margin: "0.5%",
        //   suffix: "ms",
        //   min: 0,
        //   max: MAX_LB_TIMEOUT,
        //   rules: number()
        //     .integer()
        //     .nullable()
        //     .transform(
        //       (value: number | null, originalValue: string | number) => {
        //         if (
        //           typeof originalValue === "string" &&
        //           originalValue.trim() === ""
        //         ) {
        //           return null;
        //         }
        //         return typeof value === "number" ? value : null;
        //       }
        //     )
        //     .min(0, ERROR_MESSAGES.LB_TIMEOUT)
        //     .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
        //   isHidden: (fieldValues) => {
        //     return (
        //       !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
        //     );
        //   },
        //   label: "Member Connect Timeout"
        // },
        // {
        //   name: "member_data_timeout",
        //   type: FIELD_TYPES.NUMBER,
        //   defaultValue: 50000,
        //   fullWidth: false,
        //   width: "33%",
        //   margin: "0px",
        //   suffix: "ms",
        //   min: 0,
        //   max: MAX_LB_TIMEOUT,
        //   rules: number()
        //     .integer()
        //     .nullable()
        //     .transform(
        //       (value: number | null, originalValue: string | number) => {
        //         if (
        //           typeof originalValue === "string" &&
        //           originalValue.trim() === ""
        //         ) {
        //           return null;
        //         }
        //         return typeof value === "number" ? value : null;
        //       }
        //     )
        //     .min(0, ERROR_MESSAGES.LB_TIMEOUT)
        //     .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
        //   isHidden: (fieldValues) => {
        //     return (
        //       !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
        //     );
        //   },
        //   label: "Member Data Timeout"
        // },
        {
          name: "headerInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: `\n\nInsert Headers:`
        },
        {
          name: "x_forwarded_for",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-For"
        },
        {
          name: "x_forwarded_proto",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-Proto"
        },
        {
          name: "x_forwarded_port",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-Port"
        },
        {
          name: "divider2",
          type: FIELD_TYPES.DIVIDER,
          label: "Pool Details"
        },
        {
          name: "algorithm",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Algorithm",
          helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
          options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
            label: LB_POOL_ALGORITHM_LABELS[x],
            value: x
          })),
          defaultValue: LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
          rules: string()
        },
        {
          name: "session_persistence",
          type: FIELD_TYPES.SELECT,
          label: "Session Persistence",
          variant: "outlined",
          options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map((x) => ({
            label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
            value: x
          })),
          helperText:
            "Type of session persistence for routing traffic to pool members.",
          defaultValue: {
            label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
            value: LB_POOL_SESSION_PERSISTENCE.None
          },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "cookie_name",
          type: FIELD_TYPES.TEXT,
          label: "Cookie Name",
          isHidden: (fieldValues) => {
            const persistence =
              fieldValues.session_persistence as SelectOption | null;

            return (
              !persistence ||
              persistence.value !==
                String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
            );
          },
          rules: string()
        },
        {
          name: "divider3",
          type: FIELD_TYPES.DIVIDER,
          label: "Health Monitor Details"
        },
        {
          name: "monitor_type",
          type: FIELD_TYPES.SELECT,
          label: "Health Monitor Type",
          helperText:
            "Depends on the listener's protocol and defines how health checks are performed.",
          variant: "outlined",
          options: Object.keys(LB_MONITOR_TYPES_LABELS).map((x) => ({
            label: LB_MONITOR_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: {
            label: LB_MONITOR_TYPES_LABELS.HTTP,
            value: LB_MONITOR_TYPES.HTTP
          },
          rules: selectOptionSchema
        },
        {
          name: "timeout",
          helperText: "The time health check waits before failing.",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 5,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, "The timeout must be a number greater than or equal to 0."),
          label: "Timeout"
        },
        {
          name: "delay",
          type: FIELD_TYPES.NUMBER,
          helperText: "The interval between health checks.",
          defaultValue: 5,
          fullWidth: false,
          width: "49%",
          margin: "0",
          suffix: "sec",
          min: 0,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .test(
              "delay-greater-than-timeout",
              "The health check interval must be greater than or equal to the timeout.",
              function (delay) {
                const { timeout } = this.parent;
                if (delay == null || timeout == null) return true;
                return delay >= timeout;
              }
            ),
          label: "Delay"
        },
        {
          name: "max_retries",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member deactivation",
          defaultValue: 3,
          fullWidth: false,
          width: "49%",
          margin: "2%",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Connection Retries"
        },
        {
          name: "max_retries_down",
          type: FIELD_TYPES.NUMBER,
          helperText: "Attempts before member error",
          defaultValue: 3,
          fullWidth: false,
          width: "49%",
          margin: "0",
          step: 1,
          min: 1,
          max: 10,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number between 1 and 10.")
            .max(10, "The value must be a number between 1 and 10."),
          label: "Max Connection Retries Down"
        },
        {
          name: "http_method",
          type: FIELD_TYPES.SELECT,
          label: "HTTP Method",
          helperText:
            "HTTP method for the health check. Active only when the monitor type is HTTP or HTTPS.",
          variant: "outlined",
          options: Object.keys(LB_MONITOR_HTTP_METHODS_LABELS).map((x) => ({
            label: LB_MONITOR_HTTP_METHODS_LABELS[x],
            value: x
          })),
          defaultValue: {
            label: LB_MONITOR_HTTP_METHODS_LABELS.GET,
            value: LB_MONITOR_HTTP_METHODS.GET
          },
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "expected_codes",
          type: FIELD_TYPES.TEXT,
          label: "Expected Codes",
          helperText:
            "Set the HTTP status codes that indicate a successful health check.",
          defaultValue: 200,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(
            /^\d+(-\d+)?(,\d+(-\d+)?)*$/,
            "Only numbers, ranges (e.g., 200-204), or comma-separated values are allowed"
          )
        },
        {
          name: "url_paths",
          type: FIELD_TYPES.TEXT,
          label: "URL Path",
          helperText: "Set target URL for the health check request.",
          defaultValue: "/",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.monitor_type ||
              ((fieldValues.monitor_type as SelectOption).value !==
                String(LB_MONITOR_TYPES.HTTP) &&
                (fieldValues.monitor_type as SelectOption).value !==
                  String(LB_MONITOR_TYPES.HTTPS))
            );
          },
          rules: string().matches(/^\/.*/, "URL Path must start with '/'")
        }
      ]
    },
    [DIALOG_TYPES.EDIT_LOAD_BALANCER]: {
      onConfirm: handleConfirmEditLoadBalancer,
      title: "Edit Load Balancer",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            tableLoadBalancers?.find((lb) => lb.id === selectedItemId)?.name ||
            "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            tableLoadBalancers?.find((lb) => lb.id === selectedItemId)
              ?.description || "",
          rules: string()
        },
        {
          name: "lb_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tableLoadBalancers?.find(
            (lb) => lb.id === selectedItemId
          )?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.DELETE_LOAD_BALANCER]: {
      onConfirm: handleConfirmDeleteLoadBalancer,
      title: `Are you sure you want to delete "${
        deletingLoadBalancerName ?? "selected"
      }" load balancer?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmAssociateFloatingIP,
      title: "Associate floating IP",
      confirmButtonLabel: "Associate",
      fields: [
        {
          name: "floatingIP",
          type: FIELD_TYPES.SELECT,
          label: "Floating IP",
          options: () =>
            floatingIPs
              ?.filter((fip) => fip.status != "ACTIVE")
              .map((fip) => getSelectOption(fip, "floating_ip_address", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DISASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmDisassociateFloatingIP,
      title: "Are you sure you want to dissociate floating IP with this LB?",
      confirmButtonLabel: "Dissociate"
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        <Badge
          color="warning"
          badgeContent={
            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              beta
            </span>
          }
          style={{ paddingRight: "20px" }}
        >
          {title}
        </Badge>
      </Typography>
      <Table
        key={"loadBalancersTable"}
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={tableLoadBalancers || []}
        columns={tableLoadBalancersColumns}
        actions={tableLoadBalancersActions}
        itemLink={{
          column: "name",
          getURL: generateLoadBalancersTableItemURL
        }}
        isLoading={!tableLoadBalancers}
        toolbarItems={
          <Button
            onClick={handleCreateLoadBalancerButtonClick}
            variant={"contained"}
          >
            Create Load Balancer
          </Button>
        }
      />

      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
